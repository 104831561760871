import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";
import queryClient from "data/queryClient";
import isStringNullOrEmpty from "shared/utils/isStringNullOrEmpty";

function fetchPeopleUnrestricted(page = 0, filter = "") {
  return fetch(
    `/v1/pessoa-simplified?size=100&index=${page}&q=${encodeURIComponent(filter)}`
  ).then((payload) => payload.data);
}

const getQueryKey = (page = 0, name = "") => [
  "people-unrestricted",
  page,
  name,
];

export function usePeopleUnrestrictedQuery({
  page = 0,
  filter = "",
  ...other
}) {
  const query = useQuery({
    ...other,
    queryKey: getQueryKey(page, filter),
    queryFn: () => fetchPeopleUnrestricted(page, filter),
    enabled: !isStringNullOrEmpty(filter),
  });

  return {
    ...query,
    isLoading: query.isLoading && query.fetchStatus !== "idle",
  };
}

export function ensurePeopleUnrestrictedQuery({ page = 0, filter = "" } = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(page, filter),
    queryFn: () => fetchPeopleUnrestricted(page, filter),
  });
}

export function invalidatePeopleUnrestrictedQuery() {
  return queryClient.invalidateQueries(["people-unrestricted"]);
}
