import fetch from "data/fetch";
import { invalidateCultoSecretariasQuery } from "data/queries/culto-secretaria/queryCultoSecretarias";
import { ensureCongregationQueryData } from "data/queries/queryCongregations";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import CultoSecretariaForm from "./components/CultoSecretariaForm.react";

export async function loader() {
  // preload congregations
  await ensureCongregationQueryData();
  return null;
}

export async function action() {
  // TODO move form submit to action
}

export default function CultoSecretariaNewPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const onSubmit = async (formData) => {
    setError(null);

    try {

      const formattedData = {
        ...formData,
        data: formData.data ? formData.data.format("YYYY-MM-DD") : null, // Ensure date is formatted
      };

      
      await fetch(`/v1/relatorio-culto-secretaria`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formattedData),
      });

      invalidateCultoSecretariasQuery();
      navigate("/culto-secretaria");
    } catch (e) {
      setError(e);
    }
  };

  return (
    <CultoSecretariaForm
      onSubmit={onSubmit}
      error={error}
      breadcrumbs={
        <Breadcrumbs
          heading={t("Create a new service secretary report")}
          links={[
            {
              name: t("Service Secretary"),
              to: "/culto-secretaria",
            },
            { name: t("New") },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />
      }
    />
  );
}
