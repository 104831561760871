import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { DONATION_TYPES } from "shared/components/DonationTypeSelect/donationTypes";

export default function DonationTypeSelect({
  required,
  label: labelProp,
  ...props
}) {
  const { t } = useTranslation();
  const data = useMemo(
    () =>
      DONATION_TYPES.map((v) => [v, t(v)]).sort((tupleA, tupleB) =>
        tupleA[1].localeCompare(tupleB[1])
      ),
    [t]
  );

  const label = (labelProp ?? t("Offering Type")) + (required ? " *" : "");

  return (
    <FormControl>
      <InputLabel>{label}</InputLabel>
      <Select {...props} label={label} required={required}>
        {data.map(([id, label]) => (
          <MenuItem key={id} value={id}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
