import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

export default function IndexPageToolbar({ 
  onSearch,
  additionalFilters,
  onAdditionalFilterChange,
}) {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = useCallback(
    (event) => {
      setSearchValue(event.target.value);
      onSearch?.(event.target.value);
    },
    [onSearch]
  );

  const handleClearSearch = useCallback(() => {
    setSearchValue("");
    onSearch?.("");
  }, [onSearch]);

  return (
    <Toolbar
      sx={{
        px: { sm: 2 },
      }}
    >
      <Stack direction="row" spacing={2} sx={{ width: "100%" , mt:2}}>
        {onSearch && (
          <OutlinedInput
            value={searchValue}
            onChange={handleSearchChange}
            placeholder={t("Search")}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "text.disabled" }} />
              </InputAdornment>
            }
            endAdornment={
              searchValue && (
                <InputAdornment position="end">
                  <Tooltip title={t("Clear search")}>
                    <IconButton onClick={handleClearSearch} edge="end">
                      <ClearIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
            }
            sx={{ width: 240 }}
          />
        )}
        {additionalFilters?.map((filter) => (
          <filter.component
            key={filter.key}
            onChange={(value) => onAdditionalFilterChange(filter.key, value)}
            {...filter.props}
          />
        ))}
      </Stack>
    </Toolbar>
  );
}
