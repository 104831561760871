import { useTranslation } from "react-i18next";
import {
  invalidateLoggedUserQuery,
  useLoggedUserData,
} from "data/queries/queryLoggedUser";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckIcon from "@mui/icons-material/Check";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import fetch from "data/fetch";
import MemberFormContactInfoCard, {
  PrefContact,
} from "./components/MemberFormContactInfoCard";
import MemberFormCongregationalInfoCard from "./components/MemberFormCongregationalInfoCard";
import MemberFormFamilyInfoCard from "./components/MemberFormFamilyInfoCard";
import MemberFormPersonalInfoCard from "./components/MemberFormPersonalInfoCard";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import Stack from "@mui/material/Stack";
import ErrorAlert from "shared/components/ErrorState/ErrorAlert.react";
import isStringNullOrEmpty from "shared/utils/isStringNullOrEmpty";
import blankToNull from "shared/utils/blankToNull";
import formFocusOnError from "shared/utils/formFocusOnError";

const INITIAL_STATE = {
  addressCity: "",
  addressCountry: "",
  addressPostCode: "",
  addressState: "",
  addressStreet: "",
  birthDate: null,
  childrenInfo: null,
  email: "",
  gender: "",
  maritalStatus: "",
  marriageAt: null,
  name: "",
  phone: "",
  photo: null,
  photoFileId: null,
  prefContact: PrefContact.EMAIL,
  spiritBaptismAt: null,
  spouseBirthDate: null,
  spouseIsMember: false,
  spouseName: "",
  waterBaptismAt: null,
};

export default function ProfileEditPage() {
  const { t } = useTranslation();
  const [formState, setFormState, dataInitializedRef] = useInitialFormState();
  const [formErrors, setFormErrors, onFieldChange] = useFormChangeHandler(
    formState,
    setFormState
  );
  const [onAddChild, onRemoveChild] = useChildEventHandlers(
    formState,
    setFormState
  );
  const contactInfoRef = useRef();
  const personalInfoRef = useRef();
  const familyInfoRef = useRef();
  const congregationalInfoRef = useRef();
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [onSubmit, isSubmitting, error] = useFormSubmit(
    formState,
    contactInfoRef,
    personalInfoRef,
    familyInfoRef,
    congregationalInfoRef,
    dataInitializedRef,
    setFormErrors,
    setShowSuccessDialog
  );

  // re-mount the form fields when initial state is ready
  const initialLoadKey = dataInitializedRef.current ? "kReady" : "kLoading";

  return (
    <>
      <Container
        component="form"
        key={initialLoadKey}
        maxWidth="sm"
        method="post"
        noValidate={true}
        onSubmit={onSubmit}
      >
        <Stack spacing={4}>
          <MemberFormContactInfoCard
            congregation={formState.congregation}
            congregationDisabled={true}
            email={formState.email}
            emailDisabled={true}
            formErrors={formErrors}
            gender={formState.gender}
            memberType={formState.memberType}
            name={formState.name}
            onCongregationChange={onFieldChange("congregation")}
            onEmailChange={onFieldChange("email")}
            onGenderChange={onFieldChange("gender")}
            onNameChange={onFieldChange("name")}
            onPhoneChange={onFieldChange("phone")}
            onPrefContactChange={onFieldChange("prefContact")}
            phone={formState.phone}
            prefContact={formState.prefContact}
            ref={contactInfoRef}
          />
          <MemberFormPersonalInfoCard
            addressCity={formState.addressCity}
            addressCountry={formState.addressCountry}
            addressPostCode={formState.addressPostCode}
            addressState={formState.addressState}
            addressStreet={formState.addressStreet}
            birthDate={formState.birthDate}
            formErrors={formErrors}
            memberType={formState.memberType}
            onAddressCityChange={onFieldChange("addressCity")}
            onAddressCountryChange={onFieldChange("addressCountry")}
            onAddressPostCodeChange={onFieldChange("addressPostCode")}
            onAddressStateChange={onFieldChange("addressState")}
            onAddressStreetChange={onFieldChange("addressStreet")}
            onBirthDateChange={onFieldChange("birthDate")}
            onPhotoChange={onFieldChange("photo")}
            photo={formState.photo}
            ref={personalInfoRef}
          />
          <MemberFormFamilyInfoCard
            ref={familyInfoRef}
            childrenInfo={formState.childrenInfo}
            formErrors={formErrors}
            maritalStatus={formState.maritalStatus}
            marriageAt={formState.marriageAt}
            memberType={formState.memberType}
            onAddChildClick={onAddChild}
            onChildChange={onFieldChange("child")}
            onMaritalStatusChange={onFieldChange("maritalStatus")}
            onMarriageAtChange={onFieldChange("marriageAt")}
            onRemoveChildClick={onRemoveChild}
            onSpouseBirthDateChange={onFieldChange("spouseBirthDate")}
            onSpouseIsMemberChange={onFieldChange("spouseIsMember")}
            onSpouseNameChange={onFieldChange("spouseName")}
            spouseBirthDate={formState.spouseBirthDate}
            spouseIsMember={formState.spouseIsMember}
            spouseName={formState.spouseName}
          />
          <MemberFormCongregationalInfoCard
            ref={congregationalInfoRef}
            extraFieldsVisible={false}
            formErrors={formErrors}
            memberType={formState.memberType}
            onSpiritBaptismAtChange={onFieldChange("spiritBaptismAt")}
            onWaterBaptismAtChange={onFieldChange("waterBaptismAt")}
            spiritBaptismAt={formState.spiritBaptismAt}
            waterBaptismAt={formState.waterBaptismAt}
          />

          <ErrorAlert error={error} />

          <Stack direction="row" justifyContent="flex-end">
            <Button
              color="success"
              disabled={isSubmitting}
              size="large"
              startIcon={<CheckIcon />}
              type="submit"
              variant="contained"
            >
              {isSubmitting ? t("Submitting...") : t("Save")}
            </Button>
          </Stack>
        </Stack>
      </Container>

      <SuccessDialog
        open={showSuccessDialog}
        onClose={() => {
          setShowSuccessDialog(false);
        }}
      />
    </>
  );
}

function SuccessDialog({ open, onClose }) {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <CheckCircleIcon
          color="success"
          fontSize="large"
          style={{ verticalAlign: "middle", marginRight: "8px" }}
        />
        {t("Profile Updated")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("Your profile has been updated successfully.")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>
          {t("OK")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/**
 * Load the profile data into the initial form statae.
 */
function useInitialFormState() {
  const dataInitializedRef = useRef(false);
  const data = useLoggedUserData();
  const [formState, setFormState] = useState(INITIAL_STATE);

  useEffect(() => {
    if (data == null || dataInitializedRef.current) {
      return;
    }

    dataInitializedRef.current = true;

    const children =
      data.familyMembers?.filter((m) => m.type === "CHILD") || [];
    const spouse = data.familyMembers?.find((m) => m.type === "SPOUSE");
    const photoFileId =
      data.documents != null &&
      data.documents.length > 0 &&
      data.documents[0].file != null
        ? data.documents[0].file.id
        : null;

    const initialState = {
      addressCity: data.address?.city,
      addressCountry: data.address?.country,
      addressPostCode: data.address?.postCode,
      addressState: data.address?.state,
      addressStreet: data.address?.street,
      birthDate: dateConvert(data.birthDate),
      childrenInfo: children.map((child) => ({
        name: child.name,
        birthDate: dateConvert(child.birthDate),
        isMember: child.isMember === true,
      })),
      congregation: data.congregacao,
      email: data.email,
      gender: data.gender,
      maritalStatus: data.maritalStatus,
      marriageAt: dateConvert(data.marriageAt),
      memberType: data.type,
      name: data.name,
      phone: data.phone,
      photo: photoFileId
        ? `/api/v1/file/${photoFileId}?width=160&height=4000`
        : null,
      photoFileId: photoFileId,
      prefContact: data.prefContact,
      spiritBaptismAt: dateConvert(data.spiritBaptismAt),
      spouseBirthDate: dateConvert(spouse?.birthDate),
      spouseIsMember: spouse?.isMember === true,
      spouseName: spouse?.name,
      waterBaptismAt: dateConvert(data.waterBaptismAt),
    };

    // the form is fully controlled, so let's fill any nulls with a valid
    // initial value
    for (const k in initialState) {
      if (initialState[k] == null) {
        initialState[k] = INITIAL_STATE[k];
      }
    }

    setFormState(initialState);
  }, [data]);

  return [formState, setFormState, dataInitializedRef];
}

/**
 * Handles changes to form inputs and places into form state
 */
function useFormChangeHandler(formState, setFormState) {
  const [formErrors, setFormErrors] = useState({});

  const onFieldChange = (field) => (value, index) => {
    const newFormState = { ...formState };
    const newFormErrors = { ...formErrors };

    if (field === "child") {
      newFormState.childrenInfo =
        formState.childrenInfo == null ? [] : [...formState.childrenInfo];
      newFormState.childrenInfo[index] = value;

      newFormErrors.childrenInfo =
        formErrors.childrenInfo == null ? {} : { ...formErrors.childrenInfo };
      newFormErrors.childrenInfo[`${index}_name`] = null;
      newFormErrors.childrenInfo[`${index}_birthDate`] = null;
    } else if (field === "photo") {
      newFormState.hasPhotoChanged = true;
      newFormState.photo = value;
      newFormState.photoFileId = null; // need new file uploaded
      newFormErrors.photo = null;
    } else {
      newFormState[field] = value;
      newFormErrors[field] = null;
    }

    setFormState(newFormState);
    setFormErrors(newFormErrors);
  };

  return [formErrors, setFormErrors, onFieldChange];
}

/**
 * Handles changes to children-specific info
 */
function useChildEventHandlers(formState, setFormState) {
  const onAddChild = () => {
    const currChildrenInfo = formState.childrenInfo ?? [];
    setFormState({
      ...formState,
      childrenInfo: [
        ...currChildrenInfo,
        { name: "", birthDate: null, isMember: false },
      ],
    });
  };

  const onRemoveChild = (index) => {
    const currChildrenInfo = formState.childrenInfo ?? [];
    const newChildrenInfro = [...currChildrenInfo];
    newChildrenInfro.splice(index, 1);
    setFormState({
      ...formState,
      childrenInfo: newChildrenInfro,
    });
  };

  return [onAddChild, onRemoveChild];
}

/**
 * Validates and submit form
 */
function useFormSubmit(
  formState,
  contactInfoRef,
  personalInfoRef,
  familyInfoRef,
  congregationalInfoRef,
  dataInitializedRef,
  setFormErrors,
  setShowSuccessDialog
) {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const validateForm = () => {
    const newFormErrors = {};

    contactInfoRef.current.validate(newFormErrors);
    personalInfoRef.current.validate(newFormErrors);
    familyInfoRef.current.validate(newFormErrors);
    congregationalInfoRef.current.validate(newFormErrors);

    setFormErrors(newFormErrors);

    const isValid = Object.keys(newFormErrors).length === 0;

    if (!isValid) {
      formFocusOnError();
    }

    return isValid;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (!dataInitializedRef.current || !validateForm()) {
      return;
    }

    setIsSubmitting(true);

    let photoFileId = formState.photoFileId;

    if (formState.hasPhotoChanged === true) {
      photoFileId = null;

      if (formState.photo) {
        try {
          const photoData = new FormData();
          photoData.append("file", formState.photo);
          photoData.append("name", formState.photo.name);
          photoData.append("mime", formState.photo.type);

          const photoResult = await fetch("/v1/file", {
            method: "POST",
            body: photoData,
          });

          photoFileId = photoResult.id;
        } catch (e) {
          setError(e);
          setIsSubmitting(false);
          return;
        }
      }
    }

    const familyMembers = [];

    if (!isStringNullOrEmpty(formState.spouseName)) {
      familyMembers.push({
        name: formState.spouseName,
        birthDate: dateFormat(formState.spouseBirthDate),
        isMember: formState.spouseIsMember,
        type: "SPOUSE",
      });
    }

    if (formState.childrenInfo != null) {
      formState.childrenInfo
        .filter(
          (c) =>
            !isStringNullOrEmpty(c.name) && !isStringNullOrEmpty(c.birthDate)
        )
        .forEach((child) => {
          familyMembers.push({
            name: child.name,
            birthDate: dateFormat(child.birthDate),
            isMember: child.isMember,
            type: "CHILD",
          });
        });
    }

    const street = blankToNull(formState.addressStreet);
    const postCode = blankToNull(formState.addressPostCode);
    const city = blankToNull(formState.addressCity);
    const state = blankToNull(formState.addressState);
    const country = blankToNull(formState.addressCountry);
    const address =
      !isStringNullOrEmpty(street) ||
      !isStringNullOrEmpty(postCode) ||
      !isStringNullOrEmpty(city) ||
      !isStringNullOrEmpty(state) ||
      !isStringNullOrEmpty(country)
        ? {
            street,
            postCode,
            city,
            state,
            country,
          }
        : null;

    const payload = {
      name: formState.name,
      phone: formState.phone,
      email: formState.email,
      gender: blankToNull(formState.gender),
      prefContact: formState.prefContact,
      birthDate: dateFormat(formState.birthDate),
      address: address,
      maritalStatus: blankToNull(formState.maritalStatus),
      marriageAt: dateFormat(formState.marriageAt),
      waterBaptismAt: dateFormat(formState.waterBaptismAt),
      spiritBaptismAt: dateFormat(formState.spiritBaptismAt),
      familyMembers,
      congregacaoId: formState.congregation?.id ?? null,
      documents: photoFileId
        ? [{ fileId: photoFileId, type: "OFFICIAL_PHOTO" }]
        : [],
    };

    try {
      await fetch("/v1/profile", {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      await invalidateLoggedUserQuery();
      setShowSuccessDialog(true);
    } catch (err) {
      setError(t("Failed to update profile. Please try again."));
    } finally {
      setIsSubmitting(false);
    }
  };

  return [onSubmit, isSubmitting, error];
}

function dateConvert(value) {
  return value == null ? null : (moment(value) ?? null);
}

function dateFormat(value) {
  return dateConvert(value)?.format("YYYY-MM-DD") ?? null;
}
