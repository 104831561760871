import fetch from "data/fetch";

export async function mutatePreDonation({
  type,
  note,
  values,
}) {
  const url = `/v1/pre-donation`;
  const method = "POST";

  const body = JSON.stringify({
    tipo: type,
    note,
    values,
  });

  try {
    const res = await fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
      },
      body,
    });

    return { success: true, data: res.data };
  } catch {
    return { success: false, data: {} };
  }
}
