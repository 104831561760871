import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { changeDpiDataUrl } from "changedpi";
import { jsPDF } from "jspdf";
import { useTranslation } from "react-i18next";
import isStringNullOrEmpty from "shared/utils/isStringNullOrEmpty";
import memberCardAssistant from "../assets/member-card-assistant.png";
import memberCardDeacon from "../assets/member-card-deacon.png";
import memberCardDefault from "../assets/member-card-default.png";
import memberCardLeader from "../assets/member-card-leader.png";
import memberCardMinister from "../assets/member-card-minister.png";
import moment from "moment";

// size and resolution comes from the card PSD template @ 300ppi
const SIZE_FOR_PRINT = [1004, 650];
// PDF sizes
const PDF_WIDTH_INCHES = 8.5; // 8.5 inches (letter size)
const PDF_HEIGHT_INCHES = 11; // 11 inches (letter size)
const CARD_WIDTH_INCHES = 3.3467;
const CARD_HEIGHT_INCHES = 2.167;
const PDF_WIDTH_POINTS = PDF_WIDTH_INCHES * 72; // 8.5 inches * 72 points/inch
const PDF_HEIGHT_POINTS = PDF_HEIGHT_INCHES * 72; // 11 inches * 72 points/inch
const CARD_WIDTH_POINTS = CARD_WIDTH_INCHES * 72;
const CARD_HEIGHT_POINTS = CARD_HEIGHT_INCHES * 72;

export function validate(member, t) {
  if (member == null) {
    return [t("Name is required")];
  }

  const issues = [];
  const { street, postCode, city, state } = member.address ?? {};

  if (member == null || isStringNullOrEmpty(member.name)) {
    issues.push(t("Name is required"));
  }

  if (isStringNullOrEmpty(member.tipo)) {
    issues.push(t("Member type is required"));
  }

  if (
    member.status !== "ATIVO" ||
    member.tipo === "CONGREGADO" ||
    member.tipo === "CONVIDADO"
  ) {
    issues.push(t("Only active members can be issued credentials"));
  }

  if (isStringNullOrEmpty(member.birthDate)) {
    issues.push(t("Date of birth is required"));
  }

  if (isStringNullOrEmpty(getPhoto(member))) {
    issues.push(t("Personal Photo is required"));
  }

  if (isStringNullOrEmpty(member.congregacao?.name)) {
    issues.push(t("Congregation is required"));
  }

  if (isStringNullOrEmpty(member.birthDate)) {
    issues.push(t("Date of birth is required"));
  }

  if (
    isStringNullOrEmpty(street) ||
    isStringNullOrEmpty(postCode) ||
    isStringNullOrEmpty(city) ||
    isStringNullOrEmpty(state)
  ) {
    issues.push(t("Address is incomplete"));
  }

  return issues;
}

const MemberCredentialCard = forwardRef(function MemberCredentialCard(
  { member },
  ref
) {
  const { t } = useTranslation();
  const canvasRef = useRef(null);

  useImperativeHandle(ref, () => {
    return {
      toDataURL() {},
      downloadAsPNG() {
        const canvas = canvasRef.current;

        if (canvas != null && member != null) {
          const link = document.createElement("a");
          const dataUrl = canvas.toDataURL("image/png", 1);
          const dataUrl300 = changeDpiDataUrl(dataUrl, 300);

          link.href = dataUrl300;
          link.download = getFileName(member) + ".png";
          link.click();
        }
      },
      downloadAsPDF() {
        const canvas = canvasRef.current;

        if (canvas != null && member != null) {
          const pdf = new jsPDF({
            unit: "pt",
            format: [PDF_WIDTH_POINTS, PDF_HEIGHT_POINTS],
            userUnit: 300,
          });
          const dataUrl = canvas.toDataURL("image/png", 1);
          const dataUrl300 = changeDpiDataUrl(dataUrl, 300);

          pdf.addImage(
            dataUrl300,
            "PNG",
            10,
            10,
            CARD_WIDTH_POINTS,
            CARD_HEIGHT_POINTS
          );
          pdf.save(getFileName(member) + ".pdf");
        }
      },
    };
  }, [member]);

  useEffect(() => {
    const canvas = canvasRef.current;

    if (canvas == null || member == null) {
      return;
    }

    const ctx = canvas.getContext("2d");

    // Fill background color (white or any design)
    ctx.fillStyle = "#FFFFFF";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Load and draw the credentials card background template
    const cardTemplate = new Image();
    cardTemplate.src = getCardFrontTemplate(member.tipo);
    cardTemplate.onload = () => {
      ctx.drawImage(cardTemplate, 0, 0, canvas.width, canvas.height);

      // Draw issued and expiration dates
      ctx.fillStyle = "#000";
      ctx.font = "bold 24px Arial";
      ctx.fillText(moment().format("MM/YY"), 129, 54);
      ctx.fillText(moment().add(13, "month").format("MM/YY"), 920, 54);

      // Draw name
      ctx.fillStyle = "#FFF";
      ctx.font = "bold 30px Arial";
      ctx.fillText(sanitizeMemberName(member.name), 282, 350);

      // Draw address
      ctx.fillText(sanitizeAddressLine1(member.address), 282, 380);
      ctx.fillText(sanitizeAddressLine2(member.address), 282, 410);

      // Draw congregation name
      ctx.fillText(
        member.congregacao.name
          .toUpperCase()
          .replace(/\(.*/, "") // anything (... is removed
          .replace(/ - .*/, "") // anything - ... is removed
          .replace(/\/.*/, "") // anything / ... is removed
          .trim()
          .substring(0, 16), // truncate
        540,
        460
      );

      // Draw DOB
      ctx.fillText(moment(member.birthDate).format("MM/DD/YYYY"), 360, 530);

      // Draw profile photo
      const imgWidth = 200;
      const imgHeight = 200 * (4 / 3);

      ctx.fillStyle = "#FFFFFF";
      ctx.fillRect(67, 280, imgWidth, imgHeight);

      const img = new Image();
      img.src = getPhoto(member);
      img.onload = () => {
        drawImageCover(ctx, img, 67, 280, imgWidth, imgHeight);
      };
    };
  }, [canvasRef, member]);

  if (member == null || validate(member, t).length !== 0) {
    return null;
  }

  return (
    <canvas
      width={SIZE_FOR_PRINT[0]}
      height={SIZE_FOR_PRINT[1]}
      ref={canvasRef}
      style={{
        width: SIZE_FOR_PRINT[0],
        maxWidth: "100%",
        aspectRatio: SIZE_FOR_PRINT[0] / SIZE_FOR_PRINT[1],
      }}
    />
  );
});

function getCardFrontTemplate(tipo) {
  switch (tipo) {
    case "PRESBITERO":
    case "EVANGELISTA":
    case "PASTOR":
      return memberCardMinister;
    case "DIACONO":
      return memberCardDeacon;
    case "LIDER":
      return memberCardLeader;
    case "COOPERADOR":
      return memberCardAssistant;
    case "MEMBRO":
      return memberCardDefault;
    default:
      throw new Error("Invalid member card for tipo " + tipo);
  }
}

function getPhoto(member) {
  const photoFileId =
    member != null &&
    member.documents != null &&
    member.documents.length > 0 &&
    member.documents[0].file != null
      ? member.documents[0].file.id
      : null;

  return photoFileId
    ? `/api/v1/file/${photoFileId}?width=667&height=4000`
    : null;
}

function getFileName(member) {
  return (
    `credentials_card_` +
    member?.name
      .normalize("NFD") // Decompose characters into base + diacritic
      .replace(/[\u0300-\u036f]/g, "") // Remove diacritics (accents)
      .replace(/[\\/\\?<>:*|":]/g, "_") // Replace invalid characters with underscores
      .replace(/\s+/g, "_") // Replace spaces with underscores
      .trim()
      .toLowerCase()
  );
}

function sanitizeMemberName(memberName) {
  return memberName.trim().toUpperCase().substring(0, 38);
}

function sanitizeAddressLine1(memberAddress) {
  return `${memberAddress.street}`.trim().toUpperCase().substring(0, 38);
}

function sanitizeAddressLine2(memberAddress) {
  return `${memberAddress.city} ${memberAddress.state}, ${memberAddress.postCode}`
    .toUpperCase()
    .substring(0, 38);
}

function drawImageCover(ctx, img, x, y, width, height) {
  const imgWidth = img.naturalWidth;
  const imgHeight = img.naturalHeight;
  const imgAspect = imgWidth / imgHeight;
  const targetAspect = width / height;

  let drawWidth, drawHeight, offsetX, offsetY;

  // Determine scaling to cover the area
  if (imgAspect > targetAspect) {
    // Image is wider than the target, scale by height
    drawHeight = height;
    drawWidth = imgWidth * (height / imgHeight);
    offsetX = x - (drawWidth - width) / 2; // Center horizontally
    offsetY = y;
  } else {
    // Image is taller than the target, scale by width
    drawWidth = width;
    drawHeight = imgHeight * (width / imgWidth);
    offsetX = x;
    offsetY = y - (drawHeight - height) / 2; // Center vertically
  }

  // Clip the area before drawing
  ctx.save();
  ctx.beginPath();
  ctx.rect(x, y, width, height);
  ctx.clip();

  // Draw the image
  ctx.drawImage(img, offsetX, offsetY, drawWidth, drawHeight);

  // Restore clipping
  ctx.restore();
}

export default MemberCredentialCard;
