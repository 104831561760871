import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';
import { useTranslation } from "react-i18next";
import { ensureEBDClasseReportsQueryData, useEBDClasseReportsQuery } from "data/queries/queryEBDClasseAllReports";

import IndexPageHeader from "shared/layouts/index/IndexPageHeader.react";
import IndexPageLayout from "shared/layouts/index/IndexPageLayout.react";
import IndexPageTable from "shared/layouts/index/IndexPageTable.react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export async function loader() {
  return await ensureEBDClasseReportsQueryData();
}

export default function EBDReportsIndexPage() {
  const { t } = useTranslation();
  const { data: report } = useEBDClasseReportsQuery({ page: 0 });

  // Process and prepare the data for the chart
  const presenceCounts = report?.data?.reduce((acc, item) => {
    item.relatorioRelations.forEach((relation) => {
      if (relation.situacao === 'PRESENTE') {
        const teacherName = item.classe.professor.name;
        const date = item.data;

        if (!acc[date]) {
          acc[date] = {};
        }
        if (!acc[date][teacherName]) {
          acc[date][teacherName] = 0;
        }
        acc[date][teacherName]++;
      }
    });
    return acc;
  }, {});

  const chartData = presenceCounts ? Object.entries(presenceCounts).map(([date, teachers]) => ({
    date,
    ...teachers
  })) : [];

  const teacherNames = chartData.length > 0 ? 
    Object.keys(chartData[0]).filter(key => key !== 'date') : [];

  return (
    <IndexPageLayout
      useQuery={useEBDClasseReportsQuery}
      hasSearch={false}
      header={
        <IndexPageHeader
          heading={t("Sunday School Reports")}
          routeIndex="/ebd/reports"
          routeNew="/ebd/reports/new"
        />
      }
      table={
        <>
          {chartData.length > 0 && (
            <BarChart
              dataset={chartData}
              xAxis={[{ scaleType: 'band', dataKey: 'date' }]}
              series={teacherNames.map(teacher => ({
                dataKey: teacher,
                label: teacher,
              }))}
              height={300}
              sx={{
                [`.${axisClasses.left} .${axisClasses.label}`]: {
                  transform: 'rotate(-90deg) translate(0px, -20px)',
                },
              }}
            />
          )}
          <IndexPageTable
            headers={[
              t("Congregation"),
              t("Teacher"),
              t("Class"),
              t("Date"),
              t("Number of Guests"),
              t("Student Name"),
              t("Status"),
              t("Comment"),
            ]}
            renderRow={({
              data,
              numeroConvidados,
              classe: { congregacao, professor, classe },
              relatorioRelations,
            }) => (
              <>
                {relatorioRelations.map(relation => (
                  <TableRow hover key={relation.id} tabIndex={-1}>
                    <TableCell>{congregacao.name}</TableCell>
                    <TableCell>{professor.name}</TableCell>
                    <TableCell>{t(classe)}</TableCell>
                    <TableCell>{data}</TableCell>
                    <TableCell>{numeroConvidados}</TableCell>
                    <TableCell>{relation.ebdClasseRelation.pessoa.name}</TableCell>
                    <TableCell>{t(relation.situacao)}</TableCell>
                    <TableCell>{relation.comentario}</TableCell>
                  </TableRow>
                ))}
              </>
            )}
          />
        </>
      }
    />
  );
} 