import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useActionData } from "react-router-dom";
import {
  Container,
  Stack,
  TextField,
  IconButton,
  Button,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DonationTypeSelect from "shared/components/DonationTypeSelect/DonationTypeSelect.react";
import FormCard from "shared/components/FormCard/FormCard.react";
import ErrorAlert from "shared/components/ErrorState/ErrorAlert.react";

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export default function PreDonationForm({ breadcrumbs, onSubmit: onSubmitProp, error: errorProp }) {
  const { t } = useTranslation();
  const [rows, setRows] = useState([{ id: Date.now(), tipo: "", value: "", note: "" }]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const actionData = useActionData();

  const toCentsFormat = (input) => {
    // Remove non-numeric characters
    input = input.replace(/\D/g, "");
  
    // Convert string to a number (ensure at least "0")
    let num = parseInt(input, 10) || 0;
  
    // Format as dollars and cents
    let formatted = (num / 100).toFixed(2);
  
    return formatted;
  };

  const updateRow = (index, field, value) => {
    setRows((prev) => {
      const updatedRows = prev.map((row, i) =>
        i === index ? { ...row, [field]: field === "value" ? toCentsFormat(value) : value } : row
      );
  
      if (index === prev.length - 1 && updatedRows[index].tipo && updatedRows[index].value) {
        updatedRows.push({ id: Date.now(), tipo: "", value: "", note: "" });
      }
  
      return updatedRows;
    });
  };

  const deleteRow = (index) => {
    setRows(rows.filter((_, i) => i !== index));
  };

  const total = rows.reduce((acc, row) => acc + (parseFloat(row.value) || 0), 0);

  const onSubmit = (e) => {
    e.preventDefault();
    if (total <= 0) return;
    const validRows = rows.filter(row => row.tipo && row.value);
    if (validRows.length === 0) return;
    setIsSubmitting(true);
    onSubmitProp({ rows: validRows }).finally(() => setIsSubmitting(false));
  };

  return (
    <Container component="form" onSubmit={onSubmit}>
      {breadcrumbs}
      <Stack spacing={2} sx={{ p: 3 }}>
        <FormCard>
          <List>
            {rows.map((row, index) => (
              <ListItem
                key={row.id}
                sx={{
                  display: "grid",
                  gridTemplateColumns: { xs: "1fr 1fr", sm: "1fr 1fr 1fr auto" },
                  gap: 1,
                  alignItems: "center",
                }}
                secondaryAction={
                  rows.length > 1 && (
                    <IconButton onClick={() => deleteRow(index)} color="error">
                      <DeleteIcon />
                    </IconButton>
                  )
                }
              >
                <DonationTypeSelect
                  fullWidth
                  value={row.tipo}
                  onChange={(e) => updateRow(index, "tipo", e.target.value)}/>
                <TextField
                  label={t("Amount")}
                  type="text"
                  fullWidth
                  value={row.value}
                  onChange={(e) => updateRow(index, "value", e.target.value)}
                  
                />
                <TextField
                  label={t("Note")}
                  fullWidth
                  sx={{ display: { xs: "none", sm: "block" } }}
                  value={row.note}
                  onChange={(e) => updateRow(index, "note", e.target.value)}
                />
              </ListItem>
            ))}
          </List>
          <Typography variant="h6">
            {t("Total")}: {currencyFormatter.format(total)}
          </Typography>
          <ErrorAlert error={errorProp} />
          <ErrorAlert error={actionData?.error} />
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Button
              variant="contained"
              color="success"
              size="large"
              disabled={isSubmitting || parseFloat(total) <= 0}
              type="submit"
            >
              {isSubmitting ? t("Submitting...") : t("Submit")}
            </Button>
          </Stack>
        </FormCard>
      </Stack>
    </Container>
  );
}
