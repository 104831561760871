import fetch from "data/fetch";
import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";

function fetchEBDClasseMembers(id) {
  return fetch(`/v1/ebd-classe/${id}/membros`);
}

const getQueryKey = (id) => ["ebd-classe", id, "members"];

export function useEBDClasseMembersQuery({ id, ...other }) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(id),
    queryFn: () => fetchEBDClasseMembers(id),
  });
}

export function ensureEBDClasseMembersQueryData(id) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(id),
    queryFn: () => fetchEBDClasseMembers(id),
  });
}

export function invalidateEBDClasseMembersQuery(id) {
  return queryClient.invalidateQueries(["ebd-classe", id, "members"]);
} 