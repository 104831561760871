import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useCultoSecretariasQuery,
  ensureCultoSecretariasQueryData,
  invalidateCultoSecretariasQuery,
} from "data/queries/culto-secretaria/queryCultoSecretarias";
import IndexPageHeader from "shared/layouts/index/IndexPageHeader.react";
import IndexPageLayout from "shared/layouts/index/IndexPageLayout.react";
import IndexPageTable from "shared/layouts/index/IndexPageTable.react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Stack from "@mui/material/Stack";
import { useCallback } from "react";
import fetch from "data/fetch";
import moment from "moment";
import usePermissions from "shared/hooks/usePermissions";

export async function loader() {
  return await ensureCultoSecretariasQueryData();
}

export default function CultoSecretariaIndexPage() {
  const { t } = useTranslation();
  const hasPermission = usePermissions();
  const handleDelete = useCallback(
    async (id) => {
      if (window.confirm(t("Are you sure you want to delete this report?"))) {
        try {
          await fetch(`/v1/relatorio-culto-secretaria/${id}`, {
            method: "DELETE",
          });
          invalidateCultoSecretariasQuery();
        } catch (error) {
          alert(t("Failed to delete the report. Please try again."));
        }
      }
    },
    [t]
  );

  return (
    <IndexPageLayout
      useQuery={useCultoSecretariasQuery}
      hasSearch={true}
      header={
        <IndexPageHeader
          heading={t("Service Secretary")}
          routeIndex="/culto-secretaria"
          routeNew="/culto-secretaria/new"
        />
      }
      table={
        <>
          <IndexPageTable
            headers={[
              t("Congregation"),
              t("Date"),
              t("Service Type"),
              t("Total"),
              t("Adults"),
              t("Children"),
              t("Guests (Believers)"),
              t("Guests (Non-Believers)"),
              t("Accepted"),
              t("Returned"),
              t("Baptisms"),
              t("Holy Spirit Baptisms"),
              t("Transfers"),
              t("Received Members"),
              t("Observation"),
              t("Actions"),
            ]}
            renderRow={({
              id,
              isRelatorioTesourariaEmpty,
              congregacao,
              data,
              tipoCulto,
              adultos,
              criancas,
              convidadosCrentes,
              convidadosNaoCrentes,
              aceitaram,
              retornos,
              batismo,
              batismoEs,
              transferencias,
              recebidos,
              obs,
            }) => {
              const total = (adultos || 0) + (criancas || 0);

              return (
                <TableRow hover key={id} tabIndex={-1}>
                  <TableCell>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      {hasPermission("read", "relatorio-culto-tesouraria") && (
                        <IconButton
                          edge="end"
                          aria-label={t("Treasury")}
                          component={RouterLink}
                          to={(isRelatorioTesourariaEmpty)?`/treasury/${id}/new`:`/treasury/${id}`}
                          sx={{
                            backgroundColor: "white",
                            borderRadius: "50%",
                            border: (isRelatorioTesourariaEmpty)?"1px solid red":"1px solid green" ,
                          }}
                        >
                          <AttachMoneyIcon sx={{ color: (isRelatorioTesourariaEmpty)?"red":"green" }} />
                        </IconButton>
                      )}

                      <Typography variant="subtitle2" noWrap>
                        {congregacao?.name ?? t("Unknown Congregation")}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Link component={RouterLink} to={`/culto-secretaria/${id}`}>
                      {moment(data).format("MMM/DD/YY")}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" noWrap>
                      {t(tipoCulto)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="subtitle2"
                      noWrap
                      sx={{ fontWeight: "bold" }}
                    >
                      {total}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" noWrap>
                      {adultos}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" noWrap>
                      {criancas}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" noWrap>
                      {convidadosCrentes}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" noWrap>
                      {convidadosNaoCrentes}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" noWrap>
                      {aceitaram}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" noWrap>
                      {retornos}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" noWrap>
                      {batismo}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" noWrap>
                      {batismoEs}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" noWrap>
                      {transferencias}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" noWrap>
                      {recebidos}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" noWrap>
                      {obs || t("No observation")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      edge="end"
                      aria-label={t("Delete")}
                      onClick={() => handleDelete(id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            }}
          />
        </>
      }
    />
  );
}
