import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useCongregationLeadersQuery } from "data/queries/queryCongregationPeople";

function CongregationLeadersSelect({
  congregationId,
  onChange,
  onInputChange,
  required,
  label,
  value,
  inputValue = "",
  disabled,
}) {
  const { t } = useTranslation();
  const { data = [] } = useCongregationLeadersQuery(congregationId);

  useEffect(() => {
    onChange && onChange(value);
  }, [onChange, value]);

  return (
    <Autocomplete
      required={required}
      value={value}
      inputValue={inputValue}
      options={data}
      autoHighlight={true}
      getOptionLabel={(option) => option.name}
      disabled={disabled}
      onChange={(_event, newValue) => {
        onChange(newValue);
      }}
      onInputChange={(_event, newInputValue) => {
        onInputChange(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={label ?? t("Leader")} />
      )}
    />
  );
}

export default CongregationLeadersSelect;
