import fetch from "data/fetch";
import { useQuery } from "@tanstack/react-query";

function fetchEBDClasseAvailableMembers(congregationId) {
  return fetch(`/v1/congregacao/${congregationId}/pessoa`).then(
    (payload) => payload.data
  );
}

const getQueryKey = (id) => [
  "congregation",
  id,
  "ebd-classe-available-members",
];

export function useEBDClasseAvailableMembers(congregationId) {
  return useQuery({
    queryKey: getQueryKey(congregationId),
    queryFn: () => fetchEBDClasseAvailableMembers(congregationId),
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: congregationId != null,
  });
} 