import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ensureEBDClasseMembersQueryData,
  useEBDClasseMembersQuery,
} from "data/queries/queryEBDClasseMembers";
import {
  ensureEBDClasseQueryData,
  useEBDClasseQuery,
} from "data/queries/queryEBDClasse";
import { invalidateEBDClasseReportsQuery } from "data/queries/queryEBDClasseReports";
import AddIcon from "@mui/icons-material/Add";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";
import fetch from "data/fetch";
import ErrorAlert from "shared/components/ErrorState/ErrorAlert.react";
import { redirect } from "react-router-dom";

const Status = {
  PRESENTE: "PRESENTE",
  AUSENTE: "AUSENTE",
  TRABALHO: "TRABALHO",
  ENFERMIDADE: "ENFERMIDADE",
  VIAGEM: "VIAGEM",
};

const NumericInput = ({ label, value, onChange }) => {
  return (
    <TextField
      label={label}
      type="text"
      value={value}
      onChange={(e) => {
        const inputValue = e.target.value;
        if (/^\d*$/.test(inputValue)) {
          onChange(inputValue);
        }
      }}
      onFocus={(e) => e.target.select()} // Select all text on focus
      onKeyDown={(e) => {
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
          e.preventDefault();
        }
      }}
      onWheel={(e) => e.target.blur()} // Prevent scroll changes
      required
    />
  );
};

const OfferingInput = ({ label, value, onChange }) => {
  return (
    <TextField
      label={label}
      type="text"
      value={value}
      onChange={(e) => {
        let inputValue = e.target.value;
        if (/^\d*\.?\d{0,2}$/.test(inputValue)) {
          onChange(inputValue);
        }
      }}
      onFocus={(e) => e.target.select()}
      onKeyDown={(e) => {
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
          e.preventDefault();
        }
      }}
      onWheel={(e) => e.target.blur()}
    />
  );
};

export async function loader({ params: { id } }) {
  return await Promise.all([
    ensureEBDClasseQueryData(id),
    ensureEBDClasseMembersQueryData(id),
  ]);
}

export async function action({ request, params: { id } }) {
  const formData = await request.formData();
  const updates = Object.fromEntries(formData);

  try {
    await fetch("/v1/ebd-classe-relatorio", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ebdClasseId: id,
        data: updates.date,
        numeroConvidados: parseInt(updates.guestCount, 10) || 0,
        relatorioRelations: JSON.parse(updates.memberReports),
      }),
    });

    return redirect(`/ebd/${id}/view`);
  } catch (error) {
    return error;
  }
}

export default function EBDReportsNewPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [date, setDate] = useState(moment());
  const [guestCount, setGuestCount] = useState(0);
  const [memberReports, setMemberReports] = useState([]);
  const [ofertaGeral, setOfertaGeral] = useState("");  
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: ebdClasse } = useEBDClasseQuery({ id });
  const { data: members } = useEBDClasseMembersQuery({ id });

  React.useEffect(() => {
    if (members?.data) {
      setMemberReports(
        members.data.map((member) => ({
          memberId: member.id,
          relationId: member.relationId,
          status: Status.PRESENTE,
          comment: "",
        }))
      );
    }
  }, [members?.data]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      await fetch("/v1/ebd-classe-relatorio", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ebdClasseId: id,
          data: date.format("YYYY-MM-DD"),
          numeroConvidados: parseInt(guestCount, 10),
          ofertaGeral: parseFloat(ofertaGeral || 0),
          relatorioRelations: memberReports.map((report) => ({
            ebdClasseRelationId: report.relationId,
            situacao: report.status,
            comentario: report.comment,
          })),
        }),
      });

      invalidateEBDClasseReportsQuery(id);
      navigate(`/ebd/${id}/view`);
    } catch (err) {
      setError(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Breadcrumbs
        heading={t("New Report")}
        links={[
          {
            name: t("Sunday School"),
            to: "/ebd",
          },
          {
            name: ebdClasse?.data?.professor?.name,
            to: `/ebd/${id}/view`,
          },
          { name: t("New Report") },
        ]}
        sx={{ mb: { xs: 3, md: 5 } }}
      />

      <Card component="form" onSubmit={onSubmit}>
        <Stack spacing={3} sx={{ p: 3 }}>
          <DatePicker
            required={true}
            label={t("Report date")}
            value={date}
            onChange={setDate}
            renderInput={(params) => <TextField {...params} required />}
          />

          <Stack direction="row" spacing={2}>
            <NumericInput
              label={t("Number of Guests")}
              value={guestCount}
              onChange={setGuestCount}
            />
            
            <OfferingInput
              label={t("Class Offering")}
              value={ofertaGeral}
              onChange={setOfertaGeral}
            />
          </Stack>

          {memberReports.map((report, index) => (
            <MemberReportFields
              key={report.memberId}
              member={members?.data?.find((m) => m.id === report.memberId)}
              report={report}
              onChange={(updates) => {
                const newReports = [...memberReports];
                newReports[index] = { ...report, ...updates };
                setMemberReports(newReports);
              }}
            />
          ))}

          <ErrorAlert error={error} />

          <Button
            startIcon={<AddIcon />}
            variant="contained"
            color="primary"
            size="large"
            disabled={isSubmitting}
            type="submit"
          >
            {isSubmitting ? t("Saving...") : t("Save")}
          </Button>
        </Stack>
      </Card>
    </Container>
  );
}

function MemberReportFields({ member, report, onChange }) {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <Typography variant="subtitle1">{member?.name}</Typography>
      <Stack direction="row" spacing={2}>
        <FormControl required={true} fullWidth>
          <InputLabel>{t("Status")}</InputLabel>
          <Select
            value={report.status}
            onChange={(e) => onChange({ status: e.target.value })}
          >
            <MenuItem value={Status.PRESENTE}>{t("Present")}</MenuItem>
            <MenuItem value={Status.AUSENTE}>{t("Absent")}</MenuItem>
            <MenuItem value={Status.TRABALHO}>{t("Work")}</MenuItem>
            <MenuItem value={Status.ENFERMIDADE}>{t("Sick")}</MenuItem>
            <MenuItem value={Status.VIAGEM}>{t("Travel")}</MenuItem>
          </Select>
        </FormControl>

        <TextField
          fullWidth
          label={t("Comment")}
          value={report.comment}
          onChange={(e) => onChange({ comment: e.target.value })}
        />
      </Stack>
    </Stack>
  );
} 