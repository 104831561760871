import React from "react";
import {
  TableCell,
  TableRow,
  IconButton,
  Typography,
  Container,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import fetch from "data/fetch";
import moment from "moment"; // Using moment for date formatting
import Stack from "@mui/material/Stack";
import IndexPageHeader from "shared/layouts/index/IndexPageHeader.react";
import IndexPageLayout from "shared/layouts/index/IndexPageLayout.react";
import IndexPageTable from "shared/layouts/index/IndexPageTable.react";
import { Outlet } from "react-router-dom";

import {
  useDonationsQuery,
  invalidateDonationsQuery,
} from "data/queries/treasury/queryDonations";

export default function TreasuryDonationPage() {
  const { t } = useTranslation();

  const handleDelete = async (id) => {
    const isConfirmed = window.confirm(
      t("Are you sure you want to delete this donation?")
    );

    if (!isConfirmed) {
      return; // Exit if user cancels
    }

    try {
      await fetch(`/v1/doacoes/${id}`, { method: "DELETE" });
      invalidateDonationsQuery();
    } catch (e) {
      alert(t("Failed to delete donation."));
    }
  };

  return (
    <Container>
      <Outlet />
      <IndexPageLayout
        useQuery={useDonationsQuery}
        hasSearch={true}
        header={
          <IndexPageHeader
            heading={t("Donations")}
            routeIndex="/treasury/donation"
            routeNew="/treasury/donation/new"
          />
        }
        table={
          <IndexPageTable
            headers={[
              t("Person Name"),
              t("Congregation"),
              t("Date"),
              t("Amount"),
              t("Type"),
              t("Source"),
              t("Note"),
              t("Actions"),
            ]}
            renderRow={({
              id,
              pessoa,
              congregacao,
              data,
              valor,
              tipo,
              source,
              note,
            }) => {
              return (
                <TableRow hover key={id} tabIndex={-1}>
                  <TableCell>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography variant="subtitle2" noWrap>
                        {pessoa?.name ?? t("Anonymous")}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" noWrap>
                      {congregacao?.name ?? t("N/A")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" noWrap>
                      {moment(data).format("MMM/DD/YY")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" noWrap>
                      {parseFloat(valor || 0).toFixed(2)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" noWrap>
                      {t(tipo)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" noWrap>
                      {t(source)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2" noWrap>
                      {note || t("No Note")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      edge="end"
                      aria-label={t("Delete")}
                      onClick={() => handleDelete(id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            }}
          />
        }
      />
    </Container>
  );
}
