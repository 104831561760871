import fetch from "data/fetch";
import { useQuery } from "@tanstack/react-query";

function fetchPeopleByCongregation(congregationId, list) {
  return fetch(
    list === ""
      ? `/v1/congregacao/${congregationId}/pessoa`
      : `/v1/congregacao/${congregationId}/pessoa?list=${list}`
  ).then((payload) => payload.data);
}

const getQueryKey = (id, list) => ["congregation", id, list];

function useCongregationPeopleInternal(congregationId, list) {
  return useQuery({
    queryKey: getQueryKey(congregationId, list),
    queryFn: () => fetchPeopleByCongregation(congregationId, list),
    enabled: congregationId != null,
  });
}

export function useCongregationLeadersQuery(congregationId) {
  return useCongregationPeopleInternal(congregationId, "LIDER_ABOVE");
}

export function useCongregationCongregadoAboveQuery(congregationId) {
  return useCongregationPeopleInternal(congregationId, "CONGREGADO_ABOVE");
}

export function useCongregationPeopleQuery(congregationId) {
  return useCongregationPeopleInternal("");
}
