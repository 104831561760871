import { IndexPageTableContext } from "shared/layouts/index/IndexPageTable.react";
import { useCallback, useMemo, useState } from "react";
import Card from "@mui/material/Card";
import IndexPageToolbar from "shared/layouts/index/IndexPageToolbar.react";
import TableContainer from "@mui/material/TableContainer";

export default function IndexPageCard({
  hasSearch = true,
  table,
  useQuery,
  queryParams = {},
  additionalFilters,
}) {
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState("");
  const [additionalFilterValues, setAdditionalFilterValues] = useState({});

  const { data: queryData, ...queryState } = useQuery({
    ...queryParams,
    page,
    filter,
    ...additionalFilterValues,
    keepPreviousData: true,
  });

  const onPageChange = useCallback((_e, page) => {
    setPage(page);
  }, []);

  const onSearch = useCallback((newFilter) => {
    setPage(0);
    setFilter(newFilter);
  }, []);

  const onAdditionalFilterChange = useCallback((key, value) => {
    setPage(0);
    setAdditionalFilterValues(prev => ({
      ...prev,
      [key]: value
    }));
  }, []);

  const tableContextValue = useMemo(
    () => ({
      filter,
      onPageChange,
      page,
      queryData,
      queryState,
    }),
    [filter, onPageChange, page, queryData, queryState]
  );

  return (
    <Card>
      <IndexPageToolbar 
        onSearch={hasSearch ? onSearch : null}
        additionalFilters={additionalFilters}
        onAdditionalFilterChange={onAdditionalFilterChange}
      />
      <TableContainer sx={{ minWidth: { lg: 800 }}}>
        <IndexPageTableContext.Provider value={tableContextValue}>
          {table}
        </IndexPageTableContext.Provider>
      </TableContainer>
    </Card>
  );
}
