import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";
import queryClient from "data/queryClient";

function fetchPeople(page = 0, filter = "", status = "") {
  const params = new URLSearchParams({
    size: 100,
    index: page,
  });
  
  if (filter) params.append("q", filter);
  if (status) params.append("status", status);
  
  return fetch(`/v1/pessoa?${params.toString()}`);
}

const getQueryKey = (page = 0, filter = "", status = "") => ["people", page, filter, status];

export function usePeopleQuery({ page = 0, filter = "", status = "", ...other } = {}) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(page, filter, status),
    queryFn: () => fetchPeople(page, filter, status),
  });
}

export function ensurePeopleQuery({ page = 0, filter = "", status = "" } = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(page, filter, status),
    queryFn: () => fetchPeople(page, filter, status),
  });
}

export function invalidatePeopleQuery() {
  return queryClient.invalidateQueries(["people"]);
}

export async function checkEmailAvailability(email, currentUserId = null) {
  if (!email) return true;
  
  const response = await fetch(`/v1/pessoa?q=${encodeURIComponent(email)}`);
  const data = response.data || [];
  
  // If updating, ignore the current user's email
  return !data.some(person => person.email === email && person.id !== currentUserId);
}
