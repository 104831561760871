import fetch from "data/fetch";
import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";

function fetchEBDClasseReports({ id, page = 0, from = "", to = "" }) {
  return fetch(
    `/v1/ebd-classe/${id}/relatorios?size=100&index=${page}` +
      (from ? `&from=${from.format("YYYY-MM-DD")}` : "") +
      (to ? `&to=${to.format("YYYY-MM-DD")}` : "")
  );
}

const getQueryKey = ({ id, page = 0, from = "", to = "" }) => [
  "ebd-classe",
  id,
  "reports",
  page,
  from,
  to,
];

export function useEBDClasseReportsQuery({
  id,
  page = 0,
  from = "",
  to = "",
  ...other
}) {
  return useQuery({
    ...other,
    queryKey: getQueryKey({ id, page, from, to }),
    queryFn: () => fetchEBDClasseReports({ id, page, from, to }),
  });
}

export function ensureEBDClasseReportsQueryData({
  id,
  page = 0,
  from = "",
  to = "",
}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey({ id, page, from, to }),
    queryFn: () => fetchEBDClasseReports({ id, page, from, to }),
  });
}

export function invalidateEBDClasseReportsQuery(id) {
  return queryClient.invalidateQueries(["ebd-classe", id, "reports"]);
} 