import { ensureCongregationQueryData } from "data/queries/queryCongregations";
import { invalidateEBDClasseQuery } from "data/queries/queryEBDClasseAll";
import { useLoaderData } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "shared/components/Breadcrumbs/Breadcrumbs.react";
import EBDClasseForm from "./components/EBDClasseForm.react";
import fetch from "data/fetch";
import { redirect } from "react-router-dom";

export async function loader({ params: { id } }) {
  await ensureCongregationQueryData();

  const { data: ebdClasse } = await fetch(`/v1/ebd-classe/${id}`);
  const { data: members } = await fetch(`/v1/ebd-classe/${id}/membros`);

  return {
    ebdClasseId: id,
    congregation: { id: ebdClasse.congregacao.id, name: ebdClasse.congregacao.name }, // Pass full object
    teacher: ebdClasse.professor ? { id: ebdClasse.professor.id, name: ebdClasse.professor.name } : null, // Ensure full object
    classe: ebdClasse.classe,
    members: members.map((m) => ({
      id: m.id,
      name: m.name,
      relationId: m.relationId,
    })),
  };
}

export async function action({ request, params: { id } }) {
  const formData = await request.formData();
  const updates = Object.fromEntries(formData);

  try {
    // Delete removed members
    const removedMembers = updates.removedMembers ? JSON.parse(updates.removedMembers) : [];
    for (const relationId of removedMembers) {
      await fetch(`/v1/ebd-classe-relation/${relationId}`, {
        method: "DELETE",
      });
    }

    // Add new members
    const newMembers = updates.newMembers ? JSON.parse(updates.newMembers) : [];
    for (const memberId of newMembers) {
      await fetch(`/v1/ebd-classe-relation/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ebdClasseId: id,
          membroId: memberId,
        }),
      });
    }

    // Update class details
    await fetch(`/v1/ebd-classe/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        professorId: updates.teacherId,
        congregacaoId: updates.congregationId,
        classe: updates.classe,
      }),
    });

    return redirect("/ebd");
  } catch (error) {
    return error;
  }
}

export default function EBDEditPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const data = useLoaderData();
  const [error, setError] = useState(null);

  const onSubmit = async ({ congregationId, teacherId, classe, selectedMembers }) => {
    try {
      // Delete removed members
      const removedMembers = data.members.filter(
        (m) => !selectedMembers.find((sm) => sm.id === m.id)
      );

      for (const member of removedMembers) {
        await fetch(`/v1/ebd-classe-relation/${member.relationId}`, {
          method: "DELETE",
        });
      }

      // Add new members
      const newMembers = selectedMembers.filter(
        (sm) => !data.members.find((m) => m.id === sm.id)
      );

      for (const member of newMembers) {
        await fetch(`/v1/ebd-classe-relation/`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ebdClasseId: data.ebdClasseId,
            pessoaId: member.id,
          }),
        });
      }

      // Update class details
      /*
      await fetch(`/v1/ebd-classe/${data.ebdClasseId}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          professorId: teacherId,
          congregacaoId: congregationId,
          classe,
        }),
      });
      */

      invalidateEBDClasseQuery();
      navigate("/ebd");
    } catch (e) {
      setError(e);
    }
  };

  const onDelete = async () => {
    if (!window.confirm(t("Are you sure you want to delete this class?"))) {
      return;
    }

    try {
      // Delete all member relations first
      for (const member of data.members) {
        await fetch(`/v1/ebd-classe-relation/${member.relationId}`, {
          method: "DELETE",
        });
      }

      // Delete class
      await fetch(`/v1/ebd-classe/${data.ebdClasseId}`, {
        method: "DELETE",
      });

      invalidateEBDClasseQuery();
      navigate("/ebd");
    } catch (e) {
      setError(e);
    }
  };

  return (
    <EBDClasseForm
      data={data}
      onSubmit={onSubmit}
      onDelete={onDelete}
      error={error}
      breadcrumbs={
        <Breadcrumbs
          heading={t("Edit Sunday School Class")}
          links={[
            {
              name: t("Sunday School"),
              to: "/ebd",
            },
            { name: t("Edit") },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />
      }
    />
  );
} 