import queryClient from "data/queryClient";
import { useQuery } from "@tanstack/react-query";
import fetch from "data/fetch";

function fetchEBDClasses(page = 0, filter = "") {
  return fetch(
    `/v1/ebd-classe?size=100&index=${page}&name=${encodeURIComponent(filter)}`
  );
}

const getQueryKey = (index = 0, name = "") => ["ebd-classe", index, name];

export function useEBDClasseQuery({ page = 0, filter = "", ...other }) {
  return useQuery({
    ...other,
    queryKey: getQueryKey(page, filter),
    queryFn: () => fetchEBDClasses(page, filter),
  });
}

export function ensureEBDClasseQueryData({ page = 0, filter = "" } = {}) {
  return queryClient.ensureQueryData({
    queryKey: getQueryKey(page, filter),
    queryFn: () => fetchEBDClasses(page, filter),
  });
}

export function invalidateEBDClasseQuery() {
  return queryClient.invalidateQueries(["ebd-classe"]);
} 