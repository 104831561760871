import {
  useCaringGroupsQuery,
  ensureCaringGroupsQueryData,
} from "data/queries/queryCaringGroups";
import { useEffect, useId, useState } from "react";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
import Avatar from "shared/components/Avatar/Avatar.react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import isStringNullOrEmpty from "shared/utils/isStringNullOrEmpty";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useErrorAlertEffect from "shared/components/ErrorState/useErrorAlertEffect";

export async function loader() {
  return await ensureCaringGroupsQueryData();
}

export default function CaringGroupsSelect({
  disabled,
  error,
  helperText,
  label,
  onChange,
  required,
  sx,
  value,
}) {
  const { t } = useTranslation();
  const id = useComponentId();
  const [inputValueDebounced, inputValue, setInputValue, isDebouncing] =
    useDebouncedState(value?.name ?? "", 300);
  const {
    data: { data = [] } = {},
    isLoading,
    error: queryError,
  } = useCaringGroupsQuery({
    page: 0,
    filter: inputValueDebounced,
  });

  useErrorAlertEffect(queryError);

  return (
    <Autocomplete
      autoComplete={true}
      autoHighlight={true}
      disabled={disabled}
      filterOptions={(x) => x}
      getOptionLabel={(option) => option.lider.name}
      id={id}
      includeInputInList={true}
      inputValue={inputValue}
      loading={isDebouncing || isLoading}
      loadingText={t("Loading...")}
      options={data}
      required={required}
      sx={sx}
      value={value}
      noOptionsText={
        isStringNullOrEmpty(inputValue)
          ? t("Type a search query")
          : t("No record found")
      }
      onChange={(_event, newValue) => {
        onChange(newValue);
      }}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderOption={(props, option) => {
        const { key, ...otherProps } = props;
        const {
          lider: { name = "", photoId },
          congregacao: { name: congregationName = "" } = {},
          departamento: departmentRaw = "",
        } = option;
        const department = t(departmentRaw);

        const nameParts = parse(
          name,
          match(name, inputValue, { insideWords: true })
        );
        const congregationNameParts = parse(
          congregationName,
          match(congregationName, inputValue, { insideWords: true })
        );
        const departmentParts = parse(
          department,
          match(department, inputValue, { insideWords: true })
        );

        return (
          <li key={key} {...otherProps}>
            <Grid container sx={{ alignItems: "center" }}>
              <Grid
                item
                sx={{
                  display: "flex",
                  width: 44,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Avatar
                  name={name}
                  sx={{
                    width: 36,
                    height: 36,
                  }}
                  src={
                    photoId != null
                      ? `/api/v1/file/${photoId}?width=72&height=72`
                      : null
                  }
                />
              </Grid>
              <Grid
                item
                sx={{
                  width: "calc(100% - 44px)",
                  wordWrap: "break-word",
                  pl: 1,
                }}
              >
                <Typography variant="body1" display="block">
                  {nameParts.map((part, index) => (
                    <Box
                      key={index}
                      component="span"
                      sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                    >
                      {part.text}
                    </Box>
                  ))}
                </Typography>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom={true}
                >
                  {congregationNameParts.map((part, index) => (
                    <Box
                      key={index}
                      component="span"
                      sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                    >
                      {part.text}
                    </Box>
                  ))}
                  {" · "}
                  {departmentParts.map((part, index) => (
                    <Box
                      key={index}
                      component="span"
                      sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                    >
                      {part.text}
                    </Box>
                  ))}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          fullWidth
          helperText={helperText}
          label={label ?? t("Caring Group") + (required ? " *" : "")}
          required={required}
          inputProps={{
            ...params.inputProps,
            autoComplete: "off",
          }}
        />
      )}
    />
  );
}

function useComponentId() {
  const id = useId();
  return `people-unrestricted-select-${id}`;
}

function useDebouncedState(initialValue, delay) {
  const [value, setValue] = useState(initialValue);
  const [debouncedValue, setDebouncedValue] = useState(initialValue);
  const [isDebouncing, setIsDebouncing] = useState(false);

  useEffect(() => {
    setIsDebouncing(true);

    const handler = setTimeout(() => {
      setDebouncedValue(value);
      setIsDebouncing(false);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return [debouncedValue, value, setValue, isDebouncing];
}
