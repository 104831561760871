/**
 * This select gives access to the entire list of people registered in our
 * system.
 *
 * While we usually retrieve a list of people based on congregation and other
 * filters, this unrestricted select is usefull in specific scenarios where
 * access to everyone is necessary. For example, selecting someone for a
 * donation, irrespective of their congregation.
 *
 * Please, favor the other PeopleSelect and scoped filtered selection when
 * possible. Use this component sparingly.
 */

import { useEffect, useId, useState } from "react";
import { usePeopleUnrestrictedQuery } from "data/queries/queryPeopleUnrestricted";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
import Avatar from "shared/components/Avatar/Avatar.react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import isStringNullOrEmpty from "shared/utils/isStringNullOrEmpty";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useErrorAlertEffect from "../ErrorState/useErrorAlertEffect";

export default function PeopleUnrestrictedSelect({
  disabled,
  error,
  helperText,
  includeAnonymous,
  label,
  onChange,
  required,
  sx,
  value,
}) {
  const { t } = useTranslation();
  const id = useComponentId();
  const [inputValueDebounced, inputValue, setInputValue, isDebouncing] =
    useDebouncedState(value?.name ?? "", 300);
  const {
    data = [],
    isLoading,
    error: queryError,
  } = usePeopleUnrestrictedQuery({
    page: 0,
    filter: inputValueDebounced,
  });
  const isAnoynmousValue =
    includeAnonymous === true && isStringNullOrEmpty(value);

  useErrorAlertEffect(queryError);

  return (
    <Autocomplete
      autoComplete={true}
      autoHighlight={true}
      disabled={disabled}
      filterOptions={(x) => x}
      getOptionLabel={(option) => option.name}
      id={id}
      includeInputInList={true}
      inputValue={inputValue}
      loading={isDebouncing || isLoading}
      loadingText={t("Loading...")}
      options={data}
      required={required}
      sx={sx}
      value={value}
      noOptionsText={
        isStringNullOrEmpty(inputValue)
          ? t("Type a search query")
          : t("No people found")
      }
      onChange={(_event, newValue) => {
        onChange(newValue);
      }}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderOption={(props, option) => {
        const { key, ...otherProps } = props;
        const {
          name = "",
          congregacao: { name: congregationName = "" } = {},
          photoId,
        } = option;

        const nameParts = parse(
          name,
          match(name, inputValue, { insideWords: true })
        );

        const congregationNameParts = parse(
          congregationName,
          match(congregationName, inputValue, { insideWords: true })
        );

        return (
          <li key={key} {...otherProps}>
            <Grid container sx={{ alignItems: "center" }}>
              <Grid
                item
                sx={{
                  display: "flex",
                  width: 44,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Avatar
                  sx={{
                    width: 36,
                    height: 36,
                    border: "2px solid rgb(255, 255, 255)",
                    bgcolor: photoId != null ? null : "primary.dark",
                  }}
                  alt={name}
                  src={
                    photoId != null
                      ? `/api/v1/file/${photoId}?width=72&height=72`
                      : null
                  }
                >
                  {name[0].toUpperCase()}
                </Avatar>
              </Grid>
              <Grid
                item
                sx={{
                  width: "calc(100% - 44px)",
                  wordWrap: "break-word",
                  pl: 1,
                }}
              >
                {nameParts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {congregationNameParts.map((part, index) => (
                    <Box
                      key={index}
                      component="span"
                      sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                    >
                      {part.text}
                    </Box>
                  ))}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          fullWidth
          helperText={helperText}
          label={(label || t("Person")) + (required ? " *" : "")}
          required={required}
          placeholder={isAnoynmousValue ? t("Anonymous Person") : undefined}
          slotProps={
            isAnoynmousValue ? { inputLabel: { shrink: true } } : undefined
          }
        />
      )}
    />
  );
}

function useComponentId() {
  const id = useId();
  return `people-unrestricted-select-${id}`;
}

function useDebouncedState(initialValue, delay) {
  const [value, setValue] = useState(initialValue);
  const [debouncedValue, setDebouncedValue] = useState(initialValue);
  const [isDebouncing, setIsDebouncing] = useState(false);

  useEffect(() => {
    setIsDebouncing(true);

    const handler = setTimeout(() => {
      setDebouncedValue(value);
      setIsDebouncing(false);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return [debouncedValue, value, setValue, isDebouncing];
}
