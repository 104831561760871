import { invalidateDonationsQuery } from "data/queries/treasury/queryDonations";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import CongregationSelect from "shared/components/CongregationSelect/CongregationSelect.react";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import fetch from "data/fetch";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import PeopleUnrestrictedSelect from "shared/components/PeopleUnrestrictedSelect/PeopleUnrestrictedSelect";
import React, { useState } from "react";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import ErrorAlert from "shared/components/ErrorState/ErrorAlert.react";
import isStringNullOrEmpty from "shared/utils/isStringNullOrEmpty";

const OfferingInput = ({ label, value, onChange }) => {
  const { t } = useTranslation();
  return (
    <TextField
      label={t(label)}
      type="text"
      fullWidth
      value={value}
      sx={{ mb: 2 }}
      onChange={(e) => {
        let inputValue = e.target.value;
        if (/^\d*\.?\d{0,2}$/.test(inputValue)) {
          onChange(inputValue);
        }
      }}
      onFocus={(e) => e.target.select()}
      onKeyDown={(e) => {
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
          e.preventDefault();
        }
      }}
      onWheel={(e) => e.target.blur()}
      required
    />
  );
};

export default function TreasuryDonationNewPage({ layout }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formError, setFormError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const [newEntry, setNewEntry] = useState({
    congregacaoId: null,
    data: "",
    registros: [
      {
        pessoaId: "",
        pessoaName: "",
        valor: 0,
        tipo: "DIZIMO",
        note: "",
      },
    ],
  });

  const donationTypes = ["DIZIMO", "OFERTA", "MISSOES", "VOTO", "OUTROS"];

  const handleAddRow = () => {
    setNewEntry({
      ...newEntry,
      registros: [
        ...newEntry.registros,
        { pessoaId: "", pessoaName: "", valor: 0, tipo: "DIZIMO", note: "" },
      ],
    });
  };

  const handleDeleteRow = (index) => {
    const updatedRows = [...newEntry.registros];
    updatedRows.splice(index, 1);
    setNewEntry({ ...newEntry, registros: updatedRows });
  };

  const handleRowChange = (index, field, value) => {
    const updatedRows = [...newEntry.registros];
    updatedRows[index][field] = value;
    setNewEntry({ ...newEntry, registros: updatedRows });
  };

  const handleSubmit = async () => {
    if (
      isStringNullOrEmpty(newEntry.congregacaoId) ||
      isStringNullOrEmpty(newEntry.data) ||
      newEntry.registros.length === 0
    ) {
      setFormError(t("All fields are required, including at least one entry."));
      return;
    }

    setFormError(null);
    setIsSubmitting(true);

    try {
      await Promise.all(
        newEntry.registros.map(async (entry) => {
          await fetch("/v1/doacoes", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              pessoaId: entry.pessoaId === "anonymous" ? null : entry.pessoaId,
              congregacaoId: newEntry.congregacaoId,
              data: newEntry.data,
              valor: parseFloat(entry.valor) || 0,
              tipo: entry.tipo,
              note: entry.note,
            }),
          });
        })
      );
      invalidateDonationsQuery();
      navigate("/treasury/donation");
    } catch (error) {
      setFormError(t("Failed to submit donation entries."));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog
      fullScreen={!isLargeScreen}
      fullWidth={true}
      maxWidth={isLargeScreen ? "lg" : "sm"}
      onClose={() => navigate("/treasury/donation")}
      open={true}
    >
      <DialogTitle>{t("New Donation")}</DialogTitle>
      <DialogContent sx={{ pb: "4px" }}>
        <RowLayout
          sx={{ mt: 1 }}
          isLargeScreen={isLargeScreen}
          slot1={
            <CongregationSelect
              required
              onChange={(value) =>
                setNewEntry({
                  ...newEntry,
                  congregacaoId: value?.id || null,
                })
              }
              sx={{ mb: 2 }}
            />
          }
          slot2={
            <TextField
              type="date"
              fullWidth
              required
              value={newEntry.data}
              onChange={(e) =>
                setNewEntry({ ...newEntry, data: e.target.value })
              }
              sx={{ mb: 2 }}
            />
          }
        />

        {newEntry.registros.map((entry, index) => (
          <RowLayout
            isLargeScreen={isLargeScreen}
            button={
              index > 0 &&
              (isLargeScreen ? (
                <IconButton
                  onClick={() => handleDeleteRow(index)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              ) : (
                <Button
                  sx={{ flexGrow: 1, flexShrink: 1, width: "100%" }}
                  variant="contained"
                  startIcon={<DeleteIcon />}
                  color="error"
                >
                  {t("Remove")}
                </Button>
              ))
            }
            slot1={
              <PeopleUnrestrictedSelect
                includeAnonymous={true}
                fullWidth
                onChange={(value) =>
                  handleRowChange(index, "pessoaId", value?.id)
                }
                sx={{ mb: 2 }}
              />
            }
            slot2={
              <OfferingInput
                label="Amount"
                value={entry.valor}
                onChange={(value) => handleRowChange(index, "valor", value)}
              />
            }
            slot3={
              <Select
                value={entry.tipo}
                onChange={(e) => handleRowChange(index, "tipo", e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
              >
                {donationTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {t(type)}
                  </MenuItem>
                ))}
              </Select>
            }
            slot4={
              <TextField
                fullWidth
                label={t("Note")}
                value={entry.note}
                onChange={(e) => handleRowChange(index, "note", e.target.value)}
                sx={{ mb: 2 }}
              />
            }
          />
        ))}

        <Button startIcon={<AddIcon />} onClick={handleAddRow}>
          {t("Add Extra Records")}
        </Button>
        <ErrorAlert error={formError} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => navigate("/treasury/donation")}>
          {t("Cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={isSubmitting}
          variant="contained"
        >
          {t("Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function RowLayout({ sx, isLargeScreen, slot1, slot2, slot3, slot4, button }) {
  return (
    <Stack
      spacing={{ xs: 0, md: 2 }}
      direction={isLargeScreen ? "row" : "column"}
      useFlexGap
      sx={{ flexGrow: 1, flexShrink: 1, ...sx }}
    >
      {[slot1, slot2, slot3, slot4].map((child, i) => (
        <Box
          sx={{
            flexGrow: 1,
            flexShrink: 1,
            width: "100%",
          }}
        >
          {child}
        </Box>
      ))}

      <Box
        sx={{
          width: !isLargeScreen ? "100%" : "40px",
          flexGrow: 0,
          flexShrink: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mb: isLargeScreen ? 2 : 4,
        }}
      >
        {button}
      </Box>
    </Stack>
  );
}
