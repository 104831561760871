import { MuiTelInput } from "mui-tel-input";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { checkEmailAvailability } from "data/queries/queryPeople";
import CongregationSelect from "shared/components/CongregationSelect/CongregationSelect.react";
import FormCard from "shared/components/FormCard/FormCard.react";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import { FormHelperText } from "@mui/material";
import isStringNullOrEmpty from "shared/utils/isStringNullOrEmpty";
import { isEmailValid } from "utils/EmailValidator";
import { isPhoneValid } from "utils/PhoneValidator";
import { forwardRef, useImperativeHandle } from "react";

export const Gender = {
  MALE: "MASCULINO",
  FEMALE: "FEMININO",
};

export const PrefContact = {
  EMAIL: "EMAIL",
  PHONE: "PHONE",
};

const MemberFormContactInfoCard = forwardRef(function MemberFormContactInfoCard(
  {
    congregation,
    congregationDisabled,
    email,
    emailDisabled,
    formErrors,
    gender,
    memberType,
    name,
    onCongregationChange,
    onEmailChange,
    onGenderChange,
    onNameChange,
    onPhoneChange,
    onPrefContactChange,
    phone,
    prefContact,
    currentUserId,
  },
  ref
) {
  const { t } = useTranslation();
  const [emailStatus, setEmailStatus] = useState({ isValid: true, message: "" });
  const isEmailRequired = !emailDisabled;
  const isCongregationRequired = !congregationDisabled;
  const requireMoreData =
    !isStringNullOrEmpty(memberType) &&
    !["CONVIDADO", "CONGREGADO"].includes(memberType);

  const handleEmailBlur = async (e) => {
    const email = e.target.value;
    if (!email) {
      setEmailStatus({ isValid: true, message: "" });
      return;
    }

    try {
      const isAvailable = await checkEmailAvailability(email, currentUserId);
      setEmailStatus({
        isValid: isAvailable,
        message: isAvailable ? t("Email available") : t("Email already in use"),
      });
    } catch (error) {
      console.error("Error checking email:", error);
      setEmailStatus({ isValid: true, message: "" });
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      validate(formErrors) {
        if (isCongregationRequired && isStringNullOrEmpty(congregation?.id)) {
          formErrors.congregation = t("Congregation is required");
        }

        if (isStringNullOrEmpty(name)) {
          formErrors.name = t("Name is required");
        }

        if (isStringNullOrEmpty(email)) {
          if (isEmailRequired) {
            formErrors.email = t("Email is required");
          }
        } else if (!isEmailValid(email)) {
          formErrors.email = t("Email is invalid");
        } else if (!emailStatus.isValid) {
          formErrors.email = t("Email already in use");
        }

        if (isStringNullOrEmpty(phone)) {
          formErrors.phone = t("Phone is required");
        } else if (!isPhoneValid(phone)) {
          formErrors.phone = t("Phone is invalid");
        }

        if (requireMoreData && isStringNullOrEmpty(gender)) {
          formErrors.gender = t("Gender is required");
        }
      },
    }),
    [
      congregation?.id,
      email,
      emailStatus.isValid,
      gender,
      isCongregationRequired,
      isEmailRequired,
      name,
      phone,
      requireMoreData,
      t,
    ]
  );

  return (
    <FormCard title={t("Contact Information")}>
      <CongregationSelect
        initialValue={congregation}
        required={isCongregationRequired}
        onChange={congregationDisabled ? null : onCongregationChange}
        disabled={congregationDisabled}
        error={!!formErrors.congregation}
        helperText={formErrors.congregation}
      />
      <TextField
        error={!!formErrors.name}
        helperText={formErrors.name}
        label={t("Name")}
        onChange={changeHandler(onNameChange)}
        required={true}
        value={name}
      />
      <FormControl error={!!formErrors.gender} variant="standard">
        <FormLabel>{t("Gender")}</FormLabel>
        <RadioGroup
          required={requireMoreData}
          row
          value={gender}
          onChange={(e) => onGenderChange(e.target.value)}
        >
          <FormControlLabel
            value={Gender.MALE}
            control={<Radio />}
            label={t("Male")}
          />
          <FormControlLabel
            value={Gender.FEMALE}
            control={<Radio />}
            label={t("Female")}
          />
        </RadioGroup>
        {formErrors?.gender != null && (
          <FormHelperText>{formErrors.gender}</FormHelperText>
        )}
      </FormControl>
      <TextField
        required={isEmailRequired}
        label={t("Email")}
        value={email}
        onChange={emailDisabled ? null : changeHandler(onEmailChange)}
        onBlur={handleEmailBlur}
        disabled={emailDisabled}
        error={!!formErrors.email || !emailStatus.isValid}
        helperText={formErrors.email || emailStatus.message}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: emailStatus.isValid && email ? 'success.main' : 
                         !emailStatus.isValid ? 'error.main' : 'inherit',
            },
            '&:hover fieldset': {
              borderColor: emailStatus.isValid && email ? 'success.main' : 
                         !emailStatus.isValid ? 'error.main' : 'inherit',
            },
          },
        }}
      />
      <MuiTelInput
        error={!!formErrors.phone}
        helperText={formErrors.phone}
        label={t("Phone")}
        onChange={onPhoneChange}
        required
        value={phone}
      />
      <FormControl error={!!formErrors.prefContact} variant="standard">
        <FormLabel id="contact-label">
          {t("Preferred contact method:")}
        </FormLabel>
        <RadioGroup
          row={true}
          aria-labelledby="contact-label"
          name="contact-group"
          value={prefContact}
          onChange={changeHandler(onPrefContactChange)}
          required={true}
        >
          <FormControlLabel
            value={PrefContact.EMAIL}
            control={<Radio />}
            label={t("Email")}
          />
          <FormControlLabel
            value={PrefContact.PHONE}
            control={<Radio />}
            label={t("Phone")}
          />
        </RadioGroup>
        {formErrors?.prefContact != null && (
          <FormHelperText>{formErrors.prefContact}</FormHelperText>
        )}
      </FormControl>
    </FormCard>
  );
});

function changeHandler(setValue) {
  return (e) => {
    setValue((e.currentTarget ?? e.target).value);
  };
}

export default MemberFormContactInfoCard;
