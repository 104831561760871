import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { DEPARTMENTS } from "./DepartmentSelect.react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, selectedNames = [], theme) {
  return {
    fontWeight:
      selectedNames.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function DepartmentSelect({
  required,
  helperText,
  label: labelProp,
  value = [],
  onChange,
  ...props
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const data = useMemo(
    () =>
      DEPARTMENTS.map((v) => [v, t(v)]).sort((tupleA, tupleB) =>
        tupleA[1].localeCompare(tupleB[1])
      ),
    [t]
  );

  const label = (labelProp ?? t("Department")) + (required ? " *" : "");

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    onChange(value);
  };

  return (
    <FormControl fullWidth error={props.error}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={value || []}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((val) => (
              <Chip key={val} label={t(val)} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
        required={required}
        {...props}
      >
        {data.map(([id, label]) => (
          <MenuItem
            key={id}
            value={id}
            style={getStyles(id, value || [], theme)}
          >
            {label}
          </MenuItem>
        ))}
      </Select>
      {helperText != null && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}
